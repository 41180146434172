@import "https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;500;700&family=Montserrat:wght@300;400;600&family=Play:wght@400;700&display=swap";
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html:lang(en) {
  --font-heading: "Kanit", sans-serif;
}

html:lang(ru) {
  --font-heading: "Play", sans-serif;
}

html {
  --font-heading: "Kanit", sans-serif;
  --font-text: "Montserrat", sans-serif;
}

p, span, a, li {
  font-size: 16px;
  font-family: var(--font-text);
}

a {
  text-decoration: none;
}

p, ul, ol {
  margin-block-end: 1rem;
}

@media print {
  @page {
    size: A4 portrait;
    padding: 0;
    margin: 29.7cm 21cm;
    background-color: unset;
  }

  html {
    font-size: 3.8mm;
  }

  body {
    background-color: unset;
    width: unset;
    min-width: unset;
  }

  a {
    color: #29298d;
    text-decoration: underline;
  }

  .pagebreakbefore {
    -webkit-column-break-before: always;
    page-break-before: always;
    break-before: always;
  }

  p, span, a, li {
    font-size: 1rem;
  }
}

/*# sourceMappingURL=index.a32043e5.css.map */
